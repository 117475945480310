/* eslint-disable camelcase */
// not changing this file to ts as generator functions with typescript have some complexities
import { call, select } from 'redux-saga/effects';
import { getToken, isValidToken, setToken } from '../utils/helper';
import { assign } from 'lodash';

const getTokenFromReduxStore = (state) => state.appReducer.enlightenManagerToken;

const COMMON_API_ERROR_MESSAGE = 'Request could not be processed';

function ApiError(message) {
  this.message = message || '';
}
ApiError.prototype = new Error();

const DEFAULT_API_OPTIONS = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

/**
 * Requests a URL, returning a promise
 * @param  {object} options The options we want to pass to "fetch"
 * @return {object} The response data
 */
function request(options) {
  return fetch(options.url, options)
    .then((response) => {
      const statusCode = response.status;
      return response.json().then((jsonResult) => {
        const result = {
          success: true,
          status: response.status,
          result: jsonResult,
          statusCode,
        };

        const { invalid_session = false } = jsonResult;
        if (statusCode === 401 && invalid_session) {
          setToken(-1);
          // Redirect to login page
          document.getElementById('login_screen').click();
          result.result.message = 'You need to sign in or sign up before continuing';
          result.isUnauthorized = true;
          result.success = false;
        }
        if (response.ok) {
          return result;
        }

        result.result.message =
          jsonResult.message || jsonResult.exception || jsonResult.info || COMMON_API_ERROR_MESSAGE;
        result.success = false;
        return result;
      });
    })
    .catch((error) => {
      const defaultErrorMessage = error instanceof ApiError ? error.message : COMMON_API_ERROR_MESSAGE;
      return {
        success: false,
        status: null,
        result: {
          message: defaultErrorMessage,
        },
      };
    });
}

const makeOptions = (options) => {
  let url = options.url;
  const { withBearer = false, enlightenManagerToken } = options;
  let jwtToken = getToken();
  if (!isValidToken(jwtToken)) {
    if (!isValidToken(enlightenManagerToken)) {
      // Redirect to login page
      document.getElementById('login_screen').click();
    } else {
      jwtToken = enlightenManagerToken;
    }
  }
  let token = withBearer ? `Bearer ${jwtToken}` : jwtToken;
  DEFAULT_API_OPTIONS.headers.Authorization = token;
  const body = options.body && JSON.stringify(options.body);
  return assign({}, DEFAULT_API_OPTIONS, options, {
    url,
    body,
  });
};

export default function* api(options) {
  const token = yield select(getTokenFromReduxStore);
  options.enlightenManagerToken = token;

  const apiOptions = makeOptions(options);
  const response = yield call(request, apiOptions);
  const { success, result } = response;
  if (!success) {
    console.log('response result is', result);
  }
  return response;
}

export function* get(options) {
  return yield call(api, assign({}, options, { method: 'GET' }));
}

export function* post(options) {
  return yield call(api, assign({}, options, { method: 'POST' }));
}

export function* putMethod(options) {
  return yield call(api, assign({}, options, { method: 'PUT' }));
}

export function* deleteMethod(options) {
  return yield call(api, assign({}, options, { method: 'DELETE' }));
}

export function* patchMethod(options) {
  return yield call(api, assign({}, options, { method: 'PATCH' }));
}

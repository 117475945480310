/* eslint-disable no-shadow */
import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import {
  forwardTo,
  setToken,
  isCompanyIdValid,
  constructQueryString,
  openInSameWindow,
  parseParams,
  getToken,
  isValidToken,
  getCountryBasedLocale,
} from '../../utils/helper';
import { setDrsId, setPaymentStatusFromStore } from '../Installer/action';
import {
  setMobileView,
  setEnlightenManagerToken,
  getAccountData,
  getSiteDetails,
  setCompanyId,
  setFromITK,
} from './action';
import { getABFeatureList, getUserOwnPreferences, getUserDetails, getCompanyInfo } from '../Header/action';
import settings from '../../utils/settings';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { browserHistory as history } from '../../routes/index';
import { get, isEmpty } from 'lodash';
import I18n from '../../config/locales';
import getMoment from '../../components/Temp/components/moment';
import Breadcrumbs from '../../components/Breadcrumbs';
import LeadLeftPanelAccordion from '../../components/Temp/components/LeftPanelAccordion';
import { Box, Button, Drawer, Grid } from '@material-ui/core';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  setLeadDetails,
  setLeftPanelOpenList,
  getSystemDetails,
  getLeadDetails,
  getLeadFromJourneyId,
} from '../../components/Temp/containers/LeadManagement/actions';
import feature from '../../components/Temp/utils/feature';
import { setValue, getValue } from '../../utils/localStorage';
import Banner from '../../components/Common/Banner';
import { SUPPORTED_LOCALES } from '../Installer/constants';

const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: theme.spacing(7),
  },
  mobileBody: {
    marginTop: theme.spacing(6.25),
  },
  leftAccordion: {
    padding: '0 !important',
    borderRight: '0.5px solid #3359773d',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 15px #0000001A',
    position: 'fixed',
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    height: 'calc(100% - 56px)',
  },
  mobileLeftAccordion: {
    height: 'calc(100% - 45px)',
  },
  isMobileLeftAccordion: {
    position: 'relative',
    height: '100%',
  },
  systemMenuButton: {
    border: 'none',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: 16,
    background: '#FAFAFA 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 15px #0000001a',
    margin: theme.spacing(2),
    position: 'fixed',
    zIndex: 1,
    '&:hover': {
      border: 'none',
      background: 'none',
    },
  },
  rightContainer: {
    height: '100%',
  },
  rightBody: {
    padding: 0,
    width: 'calc(100% - 280px)',
    marginLeft: theme.spacing(35),
  },
  rightBodyNonUS: {
    padding: 0,
    width: 'calc(100% - 60px)',
    marginLeft: theme.spacing(5),
  },
  mobileRightBody: {
    width: '100%',
    marginLeft: theme.spacing(0),
    padding: theme.spacing(0, 2, 2.5),
    marginTop: theme.spacing(6),
  },
  root: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  rootWithLeftPanel: {
    padding: 0,
  },
}));

const Layout = ({
  children,
  isMobileView,
  matchProps,
  isInstaller,
  hasLeftPaneProp,
  fromITK,
  countryCode,
  isPermitPitchEnabled = false,
}) => {
  const classes = useStyles();
  const belongsToUs = countryCode === 'US';
  const isMobile = !useMediaQuery('(min-width:768px)');
  const [isLeftPaneOpen, setIsLeftPaneOpen] = useState(false);
  const { search, pathname } = matchProps.location;
  const { siteId = '' } = parseParams(search);
  let hasLeftPane = pathname.includes('sgip-incentive') && !siteId ? false : hasLeftPaneProp;
  isPermitPitchEnabled = isPermitPitchEnabled && isInstaller && pathname === '/manager/dashboard/design-review';

  const leftPaneAccordion = (
    <Grid
      className={clsx(
        classes.leftAccordion,
        isMobileView && classes.mobileLeftAccordion,
        isMobile && classes.isMobileLeftAccordion
      )}
    >
      <LeadLeftPanelAccordion
        isLeftPaneOpen={isLeftPaneOpen}
        isMobile={isMobile}
        closeLeftPanel={() => setIsLeftPaneOpen(false)}
        {...matchProps}
      />
    </Grid>
  );

  return (
    <Fragment>
      {!fromITK && <Header {...history} {...matchProps} />}
      <Box className={isMobileView ? clsx(!fromITK && classes.mobileBody) : clsx(!fromITK && classes.body)}>
        {!hasLeftPane ? (
          <Fragment>
            {isPermitPitchEnabled && <Banner />}
            <Breadcrumbs matchProps={matchProps} isInstaller={isInstaller} history={history} />
            <Box className={classes.root}>{children}</Box>
            {!fromITK && <Footer {...history} {...matchProps} />}
          </Fragment>
        ) : (
          <Fragment>
            {isMobile && belongsToUs && (
              <Button
                className={classes.systemMenuButton}
                color="primary"
                variant="outlined"
                onClick={() => setIsLeftPaneOpen(true)}
              >
                System Menu
              </Button>
            )}
            <Grid container direction="row" className={classes.rightContainer}>
              {isMobile ? (
                <Drawer anchor="left" open={isLeftPaneOpen} onClose={() => setIsLeftPaneOpen(false)}>
                  {leftPaneAccordion}
                </Drawer>
              ) : (
                belongsToUs && leftPaneAccordion
              )}
              <Grid
                className={clsx(
                  belongsToUs ? classes.rightBody : classes.rightBodyNonUS,
                  isMobile && classes.mobileRightBody
                )}
              >
                <Breadcrumbs matchProps={matchProps} isInstaller={isInstaller} history={history} />
                <Box className={clsx(classes.root, classes.rootWithLeftPanel)}>{children}</Box>
                {!fromITK && <Footer {...history} {...matchProps} />}
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Box>
      <a id="login_screen" href={settings.enlightenUrl('login')} referrerPolicy="no-referrer-when-downgrade" />
    </Fragment>
  );
};

class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    const { search, pathname } = props.location;
    let { token } = parseParams(search);
    let cookieToken = getToken(settings.getTokenCookieName());
    // eslint-disable-next-line no-console
    console.log(token, 'token=');
    console.log(cookieToken, 'cookieToken=');
    if (isValidToken(cookieToken)) {
      token = cookieToken;
    }

    const { status, from, platform } = parseParams(search);
    const { drs_id: drsId } = props.computedMatch.params;
    this.systemDetailsUpdated = false;
    this.siteIdFromParam = '';
    this.state = {
      isPermitPitchEnabled: false,
    };
    if (token && pathname) {
      const params = constructQueryString({ from: from, platform: platform });
      // eslint-disable-next-line no-console
      console.log(token, 'Inside Inf');
      setToken(token);
      forwardTo(pathname + params);
    }
    if (token) {
      if (!getValue('referrer')) {
        setValue('referrer', document.referrer);
      }
      // eslint-disable-next-line no-console
      console.log(token, 'tokentoken');
      this.props.setEnlightenManagerToken(token);
    }
    if (drsId) {
      props.setDrsId(drsId);
    }
    if (platform === 'itk') {
      props.setFromITK(true);
    }
    if (status) {
      forwardTo(pathname);
      props.setPaymentStatusFromStore(status);
    }
  }

  handleWindowSizeChange = () => {
    const isMobileView = window.innerWidth <= 600;
    this.props.setMobileView(isMobileView);
  };

  componentDidMount = () => {
    this.props.getAccountData({ cbk: this.accountSuccessCbk });
    this.handleWindowSizeChange();
    window.addEventListener('resize', this.handleWindowSizeChange);
  };

  accountSuccessCbk = (accountData) => {
    if (this.props.isUnauthorized) {
      document.getElementById('login_screen').click();
    } else {
      const companyId = get(accountData, 'company_id', '') || '';
      this.props.setCompanyId(companyId);
      const email = get(accountData, 'email', '') || '';
      if (isCompanyIdValid(companyId)) {
        this.companyInfo(email, companyId);
      }
      this.props.getUserOwnPreferences();
    }
  };

  companyInfoCbk = (email, companyId) => {
    this.props.getABFeatureList({
      email,
      companyId,
      cbk: () => {
        const { accountDetail, featureList } = this.props;
        const isSelfInstaller = get(accountDetail, 'self_installer', false);
        const isTypeOfAdmin =
          (get(accountDetail, 'service_roles.drs_service_role', 'no_access') || 'no_access') !== 'no_access';
        let isDRSEnabled = false;
        if (!isEmpty(featureList)) {
          if (isSelfInstaller) {
            isDRSEnabled = feature.isFeatureEnabled('DRS_ENABLED_USER');
          } else {
            isDRSEnabled =
              isTypeOfAdmin || (isCompanyIdValid(companyId) && feature.isFeatureEnabled('DRS_ENABLED_COMPANY'));
          }
          if (this.props.countryCode === 'US' && feature.isFeatureEnabled('PERMIT_PITCH_IN_DRS')) {
            this.setState({ isPermitPitchEnabled: true });
          }
        }
        if (!isDRSEnabled) {
          openInSameWindow(settings.enlightenUrl('dashboard'));
        }
      },
    });
  };

  companyInfo = (email, companyId) => {
    this.props.getUserDetails();
    this.props.getCompanyInfo({ companyId: companyId, cbk: () => this.companyInfoCbk(email, companyId) });
  };

  componentDidUpdate(prevProps) {
    const {
      locale,
      location,
      systemDetails,
      leadDetails = {},
      computedMatch = {},
      hasLeftPane,
      leftPanelOpenList,
      setLeftPanelOpenList,
      setLeadDetails,
      getSystemDetails,
      getLeadDetails,
      accountDetail,
      getSiteDetails,
      getLeadFromJourneyId,
    } = this.props;
    getMoment(locale);
    const { search } = location;
    const { siteId = '' } = parseParams(search);
    const companyId = get(accountDetail, 'company_id', '') || '';
    if (!isEmpty(systemDetails)) {
      this.systemDetailsUpdated = true;
      const leadId = get(systemDetails, 'active_lead_id', '') || '';
      const linkedLeadJourneyId = get(systemDetails, 'linked_installer_lead_journey_id', '') || '';
      if (linkedLeadJourneyId && isEmpty(leadDetails)) {
        getLeadFromJourneyId({ leadJourneyId: linkedLeadJourneyId });
      } else if (!isEmpty(leadId)) {
        getLeadDetails({
          companyId: companyId,
          lead_id: leadId,
          journey_type: 'Turnkey',
          redirect: () => {},
        });
      }
    }
    I18n.defaultLocale = 'en';
    if (prevProps.countryCode !== this.props.countryCode) {
      var localeId = locale ? locale : getValue('locale_id');
      I18n.locale = SUPPORTED_LOCALES.includes(localeId) ? locale : getCountryBasedLocale(this.props.countryCode) || '';
      I18n.fallbacks = true;
    }
    const siteIdFromParam =
      get(computedMatch, 'params.source_id') || get(computedMatch, 'params.site_id') || siteId || this.props.siteId;
    let leadLinkedSiteId = '';
    if (!isEmpty(leadDetails) && !isEmpty(get(leadDetails, 'linked_site_id', ''))) {
      leadLinkedSiteId = get(leadDetails, 'linked_site_id').toString();
    }
    if (
      hasLeftPane &&
      ((siteIdFromParam && !this.systemDetailsUpdated) ||
        (siteIdFromParam && siteIdFromParam !== this.siteIdFromParam) ||
        (leadLinkedSiteId && siteIdFromParam !== leadLinkedSiteId))
    ) {
      const openList = leftPanelOpenList.map((list) => {
        list.isOpen = false;
        return list;
      });
      setLeftPanelOpenList(openList);
      // eslint-disable-next-line no-unused-expressions
      siteIdFromParam !== leadLinkedSiteId && setLeadDetails({});
      getSystemDetails({ siteId: siteIdFromParam });
      getSiteDetails({ siteId: siteIdFromParam });
      this.systemDetailsUpdated = true;
    }
    this.siteIdFromParam = siteIdFromParam;
  }

  render() {
    const {
      component: Component,
      isMobileView,
      loading,
      accountDetail,
      isInstaller,
      hasLeftPane = false,
      fromITK = false,
      countryCode,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Layout
            isMobileView={isMobileView}
            loading={loading}
            countryCode={countryCode}
            matchProps={matchProps}
            isInstaller={isInstaller}
            hasLeftPaneProp={hasLeftPane}
            fromITK={fromITK}
            isPermitPitchEnabled={this.state.isPermitPitchEnabled}
          >
            <Component {...matchProps} />
          </Layout>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMobileView: (isMobileView) => dispatch(setMobileView(isMobileView)),
  setEnlightenManagerToken: (tokenVal) => dispatch(setEnlightenManagerToken(tokenVal)),
  setDrsId: (drsId) => dispatch(setDrsId(drsId)),
  setPaymentStatusFromStore: (status) => dispatch(setPaymentStatusFromStore(status)),
  getAccountData: (payload) => dispatch(getAccountData(payload)),
  getABFeatureList: (payload) => dispatch(getABFeatureList(payload)),
  getUserDetails: () => dispatch(getUserDetails()),
  getUserOwnPreferences: () => dispatch(getUserOwnPreferences()),
  getCompanyInfo: (companyId) => dispatch(getCompanyInfo(companyId)),
  setLeftPanelOpenList: (payload) => dispatch(setLeftPanelOpenList(payload)),
  setLeadDetails: (leadDetails) => dispatch(setLeadDetails(leadDetails)),
  getSystemDetails: (payload) => dispatch(getSystemDetails(payload)),
  getLeadDetails: (payload) => dispatch(getLeadDetails(payload)),
  getSiteDetails: (payload) => dispatch(getSiteDetails(payload)),
  getLeadFromJourneyId: (payload) => dispatch(getLeadFromJourneyId(payload)),
  setCompanyId: (payload) => dispatch(setCompanyId(payload)),
  setFromITK: (payload) => dispatch(setFromITK(payload)),
});

const mapStateToProps = (state) => ({
  isMobileView: state.appReducer.isMobileView,
  loading: state.appReducer.loading,
  enlightenManagerToken: state.appReducer.enlightenManagerToken,
  isUnauthorized: state.appReducer.isUnauthorized,
  locale: state.appReducer.locale,
  accountDetail: state.appReducer.accountDetail,
  featureList: state.appReducer.featureList,
  isInstaller: state.appReducer.isInstaller,
  systemDetails: state.leadReducer.systemDetails,
  leadDetails: state.leadReducer.leadDetails,
  leftPanelOpenList: state.leadReducer.leftPanelOpenList,
  fromITK: state.appReducer.fromITK,
  siteId: state.installerReducer.siteId,
  countryCode: state.appReducer.countryCode,
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);

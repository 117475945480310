import React, { Fragment } from 'react';
import { forwardTo } from '../../utils/helper';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  render() {
    if (this.state.hasError) {
      forwardTo('/manager/dashboard/error');
    }
    return <Fragment>{this.props.children}</Fragment>;
  }
}

export default ErrorBoundary;

import { getValue, setValue } from '../../../utils/localStorage';
import I18n from '../config/locales';
import CountryBasedLink from '../config/constants';
import store from '../../../store';

export const constructQueryString = (queryObj) => {
  let queryString = '';
  Object.keys(queryObj).forEach((queryKey, index) => {
    const queryConnector = index === 0 ? '?' : '&';
    queryString = queryString + `${queryConnector}${queryKey}=${queryObj[queryKey]}`;
  });
  return queryString;
};

export const getToken = () => getValue('_enlighten_manager_token');

export const setToken = (value) => setValue('_enlighten_manager_token', value);

export const getSolargrafToken = () => getValue('_solargraf_token');

export const setSolargrafToken = (value) => setValue('_solargraf_token', value);

export const getCountryBasedLink = (key) => {
  const country = store.getState().appReducer.countryCode;
  const link = CountryBasedLink[country] || CountryBasedLink.US;
  return link[key];
};

export const enlightenLinks = () => ({
  dashboard: { name: I18n.t('utils.dashboard'), url: 'dashboard' },
  systems: { name: I18n.t('utils.systems'), url: 'systems' },
  account: { name: I18n.t('utils.account'), url: 'account' },
  services: { name: I18n.t('utils.services'), url: 'account' },
  dataPrivacy: { name: I18n.t('utils.dataPrivacy'), url: 'account' },
  support: { name: I18n.t('utils.support'), url: getCountryBasedLink('support'), isExternal: true },
  admin: { name: I18n.t('utils.admin'), url: 'admin' },
  adminNew: { name: I18n.t('utils.adminNew'), url: 'admin-new' },
});

export const enlightenHomeownerLinks = () => ({
  systems: { name: I18n.t('utils.systems'), url: 'systems' },
  account: { name: I18n.t('utils.account'), url: 'account' },
  support: { name: I18n.t('utils.support'), url: getCountryBasedLink('support'), isExternal: true },
});

export const isEnlm = () => {
  const hostname = window.location.hostname;
  const isProductionEnlm =
    hostname.search('enlighten.enphaseenergy') >= 0 || hostname.search('eecs.enphaseenergy.com') >= 0;
  const isPreprodEnlm = hostname.search('enlighten-preprod.enphaseenergy') >= 0;
  const isQa2Enlm = hostname.search('qa2.enphaseenergy') >= 0;
  const isQa4Enlm = hostname.search('enlighten-dev') >= 0;
  const isDevelopment = window.location.port === '7000';
  return isProductionEnlm || isPreprodEnlm || isQa2Enlm || isQa4Enlm || isDevelopment;
};

export const getEnlightenOrigin = () => {
  const hostname = window.location.hostname;
  let origin;
  if (hostname.search('drs.enphaseenergy') >= 0) {
    origin = 'https://enlighten.enphaseenergy.com';
  } else if (hostname.search('drs-ui-preprod.enphaseenergy') >= 0) {
    origin = 'https://enlighten-preprod.enphaseenergy.com';
  } else if (hostname.search('drs-ui-stg.qa-enphaseenergy') >= 0 || hostname.search('drs-stg.enphaseenergy') >= 0) {
    origin = 'https://qa2.enphaseenergy.com';
  } else if (hostname.search('drs-ui-dev.qa-enphaseenergy') >= 0 || hostname.search('drs-dev.enphaseenergy') >= 0) {
    origin = 'https://enlighten-dev.qa-enphaseenergy.com';
  } else if (window.location.port === '4200') {
    origin = 'http://localhost:7000';
  } else {
    origin = 'http://localhost:7000';
  }
  return origin;
};

export const reDirectTo = (path) => {
  window.location.replace(`${getEnlightenOrigin()}${path}?token=${getToken()}`);
};

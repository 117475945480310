import { ChatActionTypes } from './constants';
export function getMessagesList(payload) {
    return { type: ChatActionTypes.GET_ALL_MESSAGES_REQUEST, payload: payload };
}
export function sendMessage(payload) {
    return { type: ChatActionTypes.POST_MESSAGE_REQUEST, payload: payload };
}
export function updateChatStatus(payload) {
    return { type: ChatActionTypes.MARK_MESSAGES_VIEWED_REQUEST, payload: payload };
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// above should be removed once we figure out solution of action Type
// import { LayoutActions } from './action';
import { ActionTypes } from './constants';
// Header - Start
import { HeaderActionTypes } from '../Header/constants';
var initialState = {
    isMobileView: false,
    loading: false,
    emailId: '',
    enlightenManagerToken: '',
    message: '',
    messageType: '',
    isInstaller: false,
    isTier1Admin: false,
    isTier2Admin: false,
    isUnauthorized: false,
    isReviewer: false,
    isServiceAdmin: false,
    installerId: -1, // by default
    userEmail: '',
    // Header - Start
    accountDetail: {},
    viewOnlyAccess: false,
    viewCannotEdit: false,
    locale: '',
    featureList: [],
    solargrafAccess: false,
    preferencesData: {},
    companyState: '',
    einOptIn: false,
    einOptOut: false,
    countryCode: '',
    companyInfo: {},
    company: {},
    // Header - End
    // Left Panel - Start
    masterData: {},
    currentlySending: false,
    siteDetails: {},
    companyId: null,
    fromITK: false,
    showPage: false,
    applicationFilters: {
        pageNo: 0,
        pageSize: 10,
        sortOrder: '',
        sortColumn: '',
        stateIds: [-1],
        drsId: '',
        installerName: '',
        siteId: '',
        systemName: '',
        customerName: '',
        zipState: '',
        startApplicationDate: '',
        endApplicationDate: '',
        startLastModifiedDate: '',
        endLastModifiedDate: '',
        type: '',
        userType: '',
        assignedTo: [],
        status: 'current',
        projectType: '',
        country: '',
        createdDateSelectValue: '',
        lastUpdatedDateSelectValue: '',
        certificateApps: false,
    },
    // Left Panel - End
};
// The action type should be LayoutActions but that leads to couple of errors need to figure that out
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.REQUEST_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', loading: false });
        case ActionTypes.SET_MOBILE_VIEW:
            return __assign(__assign({}, state), { isMobileView: action.isMobileView });
        case ActionTypes.SET_EMAIL_ID:
            return __assign(__assign({}, state), { emailId: action.emailId });
        case ActionTypes.SET_ENLIGHTEN_MANAGER_TOKEN:
            return __assign(__assign({}, state), { enlightenManagerToken: action.enlightenManagerToken });
        case ActionTypes.ACCOUNT_DETAIL_REQUEST:
            return __assign(__assign({}, state), { loading: true });
        case ActionTypes.ACCOUNT_DETAIL_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', loading: false, isUnauthorized: action.isUnauthorized, isInstaller: action.isInstaller });
        case ActionTypes.ACCOUNT_DETAIL_SUCCESS:
            return __assign(__assign({}, state), { loading: false, installerId: action.installerId, isInstaller: action.isInstaller, isTier1Admin: action.isTier1Admin, isTier2Admin: action.isTier2Admin, isReviewer: action.isReviewer, isServiceAdmin: action.isServiceAdmin, userEmail: action.userEmail, accountDetail: action.accountDetail, locale: action.locale || 'en', viewOnlyAccess: action.viewOnlyAccess, viewCannotEdit: action.viewCannotEdit, countryCode: action.country });
        case ActionTypes.UPDATE_ACCOUNT_SUCCESS:
            return __assign(__assign({}, state), { message: action.message, messageType: 'success', loading: false });
        // Header - Start
        case HeaderActionTypes.GET_AB_FEATURE_REQUEST:
            return __assign(__assign({}, state), { loading: true });
        case HeaderActionTypes.GET_AB_FEATURE_SUCCESS:
            return __assign(__assign({}, state), { featureList: action.featureList, loading: false });
        case HeaderActionTypes.GET_AB_FEATURE_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', loading: false });
        case HeaderActionTypes.GET_USER_OWN_PREFERENCES_REQUEST:
            return __assign(__assign({}, state), { loading: true });
        case HeaderActionTypes.GET_USER_OWN_PREFERENCES_SUCCESS:
            return __assign(__assign({}, state), { preferencesData: action.preferencesData, solargrafAccess: action.solargrafAccess, loading: false });
        case HeaderActionTypes.GET_USER_OWN_PREFERENCES_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', loading: false });
        case HeaderActionTypes.GET_USER_DETAILS:
            return __assign(__assign({}, state), { loading: true });
        case HeaderActionTypes.GET_USER_DETAILS_SUCCESS:
            return __assign(__assign({}, state), { companyState: action.companyState, einOptIn: action.einOptIn, einOptOut: action.einOptOut, countryCode: action.countryCode, loading: false });
        case HeaderActionTypes.GET_USER_DETAILS_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', loading: false });
        case HeaderActionTypes.GET_COMPANY_INFO_REQUEST:
            return __assign(__assign({}, state), { loading: true });
        case HeaderActionTypes.GET_COMPANY_INFO_SUCCESS:
            return __assign(__assign({}, state), { companyInfo: action.companyInfo, company: action.company, masterData: action.masterData, loading: false, countryCode: action.countryCode });
        case HeaderActionTypes.SET_SOLARGRAF_ACCESS:
            return __assign(__assign({}, state), { solargrafAccess: action.solargrafAccess });
        // Header - End
        // Left Panel Start
        case ActionTypes.GET_SITE_DETAILS_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case ActionTypes.GET_SITE_DETAILS_SUCCESS:
            return __assign(__assign({}, state), { currentlySending: false, siteDetails: action.siteDetails });
        case ActionTypes.GET_SITE_DETAILS_FAILURE:
            return __assign(__assign({}, state), { currentlySending: false });
        case ActionTypes.SET_COMPANY_ID:
            return __assign(__assign({}, state), { companyId: action.companyId });
        // Left Panel End
        case ActionTypes.SET_FROM_ITK:
            return __assign(__assign({}, state), { fromITK: action.fromITK });
        case ActionTypes.SHOW_PAGE:
            return __assign(__assign({}, state), { showPage: action.showPage });
        case ActionTypes.SET_APPLICATIONS_FILTERS:
            return __assign(__assign({}, state), { applicationFilters: action.payload });
        default:
            return state;
    }
};
export default reducer;

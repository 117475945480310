var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { assign } from 'lodash';
import { getValue } from '../utils/localStorage';
import { drsUnivCourseMappingStg, drsUnivCourseMappingProd } from '../utils/constant';
var development = __assign({ api: 'https://qa2.enphaseenergy.com', enlightenUrl: 'https://qa2.enphaseenergy.com', digitalCoreUrl: 'https://dc-stg.enphaseenergy.com/', drsUrl: 'https://drs-api-stg.enphaseenergy.com', permittingUrl: 'https://permit-stg.enphaseenergy.com', ein_core_url: 'https://eincore-stg.enphaseenergy.com', solargraf_url: 'https://staging.solargraf.com', solargraf_api_url: 'https://api-staging.solargraf.com', drs_ui_url: 'https://drs-stg.enphaseenergy.com', enlighten_token: 'enlighten_manager_token_qa2', bom_url: 'https://enphase.com' }, drsUnivCourseMappingStg);
var test = __assign({ api: 'https://enlighten-dev.qa-enphaseenergy.com', enlightenUrl: 'https://enlighten-dev.qa-enphaseenergy.com', digitalCoreUrl: 'https://b2c-core-dev.qa-enphaseenergy.com', drsUrl: 'https://drs-api-dev.enphaseenergy.com', permittingUrl: 'https://permit-dev.enphaseenergy.com', ein_core_url: 'https://eincore-dev.enphaseenergy.com', solargraf_url: 'https://app-develop.solargraf.com', solargraf_api_url: 'https://api-develop.solargraf.com', drs_ui_url: 'https://drs-dev.enphaseenergy.com', enlighten_token: 'enlighten_manager_token_qa2', bom_url: 'https://enphase.com' }, drsUnivCourseMappingStg);
var qa2 = __assign({ api: 'https://qa2.enphaseenergy.com', enlightenUrl: 'https://qa2.enphaseenergy.com', digitalCoreUrl: 'https://dc-stg.enphaseenergy.com', drsUrl: 'https://drs-api-stg.enphaseenergy.com', permittingUrl: 'https://permit-stg.enphaseenergy.com', ein_core_url: 'https://eincore-stg.enphaseenergy.com', solargraf_url: 'https://staging.solargraf.com', solargraf_api_url: 'https://api-staging.solargraf.com', drs_ui_url: 'https://drs-stg.enphaseenergy.com', enlighten_token: 'enlighten_manager_token_qa2', bom_url: 'https://enphase.com' }, drsUnivCourseMappingStg);
var rel = __assign({ api: 'https://enlighten-rel.enphaseenergy.com', enlightenUrl: 'https://enlighten-rel.enphaseenergy.com', digitalCoreUrl: 'https://dc-stg.enphaseenergy.com', permittingUrl: 'https://permit-stg.enphaseenergy.com', ein_core_url: 'https://eincore-stg.enphaseenergy.com', solargraf_url: 'https://alpha.solargraf.com', solargraf_api_url: 'https://api-alpha.solargraf.com', drsUrl: 'https://drs-api-stg.enphaseenergy.com', drs_ui_url: 'https://drs-stg.enphaseenergy.com', enlighten_token: 'enlighten_manager_token_rel', bom_url: 'https://enphase.com' }, drsUnivCourseMappingStg);
var intg = __assign({ api: 'https://enlighten-intg.qa-enphaseenergy.com', enlightenUrl: 'https://enlighten-intg.qa-enphaseenergy.com', digitalCoreUrl: 'https://b2c-core-intg.qa-enphaseenergy.com', permittingUrl: 'https://permit-api-intg.qa-enphaseenergy.com', ein_core_url: 'https://ein-core-api-intg.qa-enphaseenergy.com', solargraf_url: 'https://alpha.solargraf.com', solargraf_api_url: 'https://api-alpha.solargraf.com', drsUrl: 'https://drs-api-intg.qa-enphaseenergy.com', drs_ui_url: 'https://drs-ui-intg.qa-enphaseenergy.com', enlighten_token: 'enlighten_manager_token_integration', bom_url: 'https://enphase.com' }, drsUnivCourseMappingStg);
var qa4 = __assign({ api: 'https://enlighten-dev.qa-enphaseenergy.com', enlightenUrl: 'https://enlighten-dev.qa-enphaseenergy.com', digitalCoreUrl: 'https://b2c-core-dev.qa-enphaseenergy.com', drsUrl: 'https://drs-api-dev.qa-enphaseenergy.com', permittingUrl: 'https://permit-api-dev.qa-enphaseenergy.com', ein_core_url: 'https://ein-core-api-dev.qa-enphaseenergy.com', solargraf_url: 'https://app-develop.solargraf.com', solargraf_api_url: 'https://api-develop.solargraf.com', drs_ui_url: 'https://drs-ui-dev.qa-enphaseenergy.com', enlighten_token: 'enlighten_manager_token_qa4', bom_url: 'https://enphase.com' }, drsUnivCourseMappingStg);
var cotqa4 = __assign({ api: 'https://enlighten-cotqa4.enphaseenergy.com', enlightenUrl: 'https://enlighten-cotqa4.enphaseenergy.com', digitalCoreUrl: 'https://b2c-core-dev.qa-enphaseenergy.com', drsUrl: 'https://drs-api-dev.enphaseenergy.com', permittingUrl: 'https://permit-dev.enphaseenergy.com', ein_core_url: 'https://eincore-dev.enphaseenergy.com', solargraf_url: 'https://app-develop.solargraf.com', solargraf_api_url: 'https://api-develop.solargraf.com', drs_ui_url: 'https://drs-ui-dev.enphaseenergy.com', enlighten_token: 'enlighten_manager_token_qa4', bom_url: 'https://enphase.com' }, drsUnivCourseMappingStg);
var preprod = __assign({ api: 'https://enlighten-preprod.enphaseenergy.com', enlightenUrl: 'https://enlighten-preprod.enphaseenergy.com', digitalCoreUrl: 'https://dc-preprod.enphaseenergy.com', drsUrl: 'https://drs-api-preprod.enphaseenergy.com', permittingUrl: 'https://permit-preprod.enphaseenergy.com', ein_core_url: 'https://ein-core-preprod.enphaseenergy.com', solargraf_url: 'https://staging.solargraf.com', solargraf_api_url: 'https://api-staging.solargraf.com', drs_ui_url: 'https://drs-preprod.enphaseenergy.com', enlighten_token: 'enlighten_manager_token_preprod', bom_url: 'https://enphase.com' }, drsUnivCourseMappingProd);
var production = __assign({ api: 'https://enlighten.enphaseenergy.com', enlightenUrl: 'https://enlighten.enphaseenergy.com', digitalCoreUrl: 'https://dc.enphaseenergy.com', drsUrl: 'https://drs-api.enphaseenergy.com', permittingUrl: 'https://permit.enphaseenergy.com', ein_core_url: 'https://ein-core.enphaseenergy.com', solargraf_url: 'https://app.solargraf.com', solargraf_api_url: 'https://api.solargraf.com', drs_ui_url: 'https://drs.enphaseenergy.com', enlighten_token: 'enlighten_manager_token_production', bom_url: 'https://enphase.com' }, drsUnivCourseMappingProd);
var DEFAULT = {};
var CONFIG = {
    development: development,
    production: production,
    preprod: preprod,
    test: test,
    qa2: qa2,
    rel: rel,
    qa4: qa4,
    cotqa4: cotqa4,
    intg: intg,
};
var getConfig = function (env) {
    var _a;
    var hostname;
    var referrer;
    var appEnv = 'development';
    if (typeof window !== 'undefined') {
        hostname = window.location.hostname;
        referrer = document.referrer;
        if (hostname.search('enlighten.enphaseenergy') >= 0 || hostname.search('drs.enphaseenergy') >= 0) {
            appEnv = 'production';
        }
        else if (hostname.search('enlighten-preprod.enphaseenergy') >= 0 ||
            hostname.search('drs-preprod.enphaseenergy') >= 0) {
            appEnv = 'preprod';
        }
        else if (hostname.search('qa2.enphaseenergy') >= 0) {
            appEnv = 'qa2';
        }
        else if (hostname.search('enlighten-intg.qa-enphaseenergy') >= 0 ||
            hostname.search('drs-ui-intg.qa-enphaseenergy') >= 0) {
            appEnv = 'intg';
        }
        else if (hostname.search('drs-stg.enphaseenergy') >= 0) {
            if ((getValue('referrer') && ((_a = getValue('referrer')) === null || _a === void 0 ? void 0 : _a.includes('https://enlighten-rel.enphaseenergy.com'))) ||
                (referrer && (referrer === null || referrer === void 0 ? void 0 : referrer.includes('https://enlighten-rel.enphaseenergy.com')))) {
                appEnv = 'rel';
            }
            else {
                appEnv = 'qa2';
            }
        }
        else if (hostname.search('enlighten-dev') >= 0 ||
            hostname.search('drs-ui-dev') >= 0 ||
            hostname.search('drs-dev') >= 0) {
            appEnv = 'qa4';
        }
        else if (hostname.search('enl(ighten)?-(cot)?qa4') >= 0) {
            appEnv = 'cotqa4';
        }
        else if (window.location.port === '4200') {
            appEnv = 'development';
        }
        else {
            appEnv = 'development';
        }
    }
    // eslint-disable-next-line no-console
    // dummy commit to see to check the automatic deployment
    console.log('DRS App running environment is:', appEnv);
    var envConfig = CONFIG[appEnv];
    envConfig.env = appEnv;
    if (envConfig) {
        return assign({}, DEFAULT, envConfig);
    }
    throw new Error('Unknown environment : ' + appEnv + env);
};
export default getConfig;

var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { take, put, call } from 'redux-saga/effects';
import { get, post } from '../../utils/api';
import settings from '../../utils/settings';
import { HeaderActionTypes } from './constants';
import { ActionTypes } from '../Layouts/constants';
import { cloneDeep, get as lodashGet } from 'lodash';
import { getToken } from '../../utils/helper';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getABFeatureList() {
    var request, _a, email, companyId, cbk, response, success, result, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(HeaderActionTypes.GET_AB_FEATURE_REQUEST)];
            case 1:
                request = _b.sent();
                _a = request.payload, email = _a.email, companyId = _a.companyId, cbk = _a.cbk;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(post, {
                        url: settings.getDrsUrl("proxyApi/request"),
                        body: {
                            url: [settings.getApiUrl("service/enlm_ms/api_internal/account/ab_features")],
                            method: ['GET'],
                            'feature[email]': [encodeURIComponent(email)],
                            'feature[company_id]': [companyId.toString()],
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: getToken(),
                        },
                    })];
            case 3:
                response = _b.sent();
                success = response.success, result = response.result;
                if (!success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: HeaderActionTypes.GET_AB_FEATURE_SUCCESS, featureList: result })];
            case 4:
                _b.sent();
                cbk();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({ type: HeaderActionTypes.GET_AB_FEATURE_FAILURE, error: result.message || result.messages })];
            case 6:
                _b.sent();
                cbk();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_1 = _b.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: getABFeatureList:', error_1);
                return [4 /*yield*/, put({ type: HeaderActionTypes.GET_AB_FEATURE_FAILURE, error: error_1 })];
            case 9:
                _b.sent();
                cbk();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getUserOwnPreferencesFlow() {
    var response, _a, alert_level, enphase_email, lead_email, metric, monthly_email, new_dashboard_view, solargraf_access, preferencesData, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(HeaderActionTypes.GET_USER_OWN_PREFERENCES_REQUEST)];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(get, {
                        url: settings.einCoreUrl("user/preferences"),
                        withBearer: true,
                    })];
            case 3:
                response = _b.sent();
                if (!response.success) return [3 /*break*/, 5];
                _a = response.result, alert_level = _a.alert_level, enphase_email = _a.enphase_email, lead_email = _a.lead_email, metric = _a.metric, monthly_email = _a.monthly_email, new_dashboard_view = _a.new_dashboard_view, solargraf_access = _a.solargraf_access;
                preferencesData = {
                    prefers_enphase_email: enphase_email,
                    prefers_monthly_email: monthly_email,
                    preferred_alert_level: alert_level,
                    preferred_lead_email: lead_email,
                    preferred_metric: metric,
                    preferred_new_dashboard_view: new_dashboard_view,
                };
                return [4 /*yield*/, put({
                        type: HeaderActionTypes.GET_USER_OWN_PREFERENCES_SUCCESS,
                        preferencesData: preferencesData,
                        solargrafAccess: solargraf_access,
                    })];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({
                    type: HeaderActionTypes.GET_USER_OWN_PREFERENCES_FAILURE,
                    error: response.result.message || response.result.messages,
                })];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_2 = _b.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: getUserOwnPreferences:', error_2);
                return [4 /*yield*/, put({ type: HeaderActionTypes.GET_USER_OWN_PREFERENCES_FAILURE, error: error_2 })];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getUserDetails() {
    var response, success, result, country, state, ein_opt_in, ein_opt_out, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(HeaderActionTypes.GET_USER_DETAILS)];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(get, {
                        url: settings.einCoreUrl('installer/companyInfo'),
                        withBearer: true,
                    })];
            case 3:
                response = _a.sent();
                success = response.success, result = response.result;
                if (!success) return [3 /*break*/, 5];
                country = result.country, state = result.state, ein_opt_in = result.ein_opt_in, ein_opt_out = result.ein_opt_out;
                return [4 /*yield*/, put({
                        type: HeaderActionTypes.GET_USER_DETAILS_SUCCESS,
                        companyState: state,
                        einOptIn: ein_opt_in,
                        einOptOut: ein_opt_out,
                        countryCode: country,
                    })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({ type: HeaderActionTypes.GET_USER_DETAILS_FAILURE, error: result.message || result.messages })];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_3 = _a.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: getUserDetails:', error_3);
                return [4 /*yield*/, put({ type: HeaderActionTypes.GET_USER_DETAILS_FAILURE, error: error_3 })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function companyInfoFlow() {
    var request, _a, companyId, cbk, enlightenApiUrl, response, success, status_1, result, country, cloneMasterData, error_4;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(HeaderActionTypes.GET_COMPANY_INFO_REQUEST)];
            case 1:
                request = _b.sent();
                _a = request.payload, companyId = _a.companyId, cbk = _a.cbk;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                enlightenApiUrl = settings.getApiUrl("service/enlm_ms/api_internal/company/".concat(companyId));
                return [4 /*yield*/, call(post, {
                        url: settings.getDrsUrl("proxyApi/request"),
                        body: { url: [enlightenApiUrl], method: ['GET'] },
                        headers: { 'Content-Type': 'application/json', Authorization: getToken() },
                    })];
            case 3:
                response = _b.sent();
                success = response.success, status_1 = response.status, result = response.result;
                if (!(success && status_1 === 200)) return [3 /*break*/, 5];
                country = lodashGet(result.company, "address.country");
                cloneMasterData = cloneDeep(result.master_data);
                cloneMasterData.year_range.reverse();
                return [4 /*yield*/, put({
                        type: HeaderActionTypes.GET_COMPANY_INFO_SUCCESS,
                        companyInfo: result.company_info,
                        company: result.company,
                        countryCode: country,
                        masterData: cloneMasterData,
                    })];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({ type: ActionTypes.REQUEST_FAILURE, error: result.message || result.messages })];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7:
                cbk();
                return [3 /*break*/, 10];
            case 8:
                error_4 = _b.sent();
                return [4 /*yield*/, put({ type: ActionTypes.REQUEST_FAILURE, error: error_4 })];
            case 9:
                _b.sent();
                cbk();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}

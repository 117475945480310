import I18n from '../config/locales';
export var userTypes = ['installer', 'tier1Admin', 'tier2Admin', 'reviewer', 'serviceAdmin'];
export var loadTemplateImage = {
    AU: 'BatterySizingTool.png',
    DE: 'siteSurvey.png',
    BE: 'siteSurvey.png',
    CH: 'siteSurvey.png',
    AT: 'siteSurvey.png',
    NL: 'siteSurvey.png',
    US: 'LoadCalculation.png',
    FR: 'siteSurveyFr_1_6.png',
};
export var loadTemplateLabel = {
    AU: 'projectDetails.batterySizingTool',
    DE: 'projectDetails.siteSurvey',
    BE: 'projectDetails.siteSurvey',
    NL: 'projectDetails.siteSurvey',
    AT: 'projectDetails.siteSurvey',
    CH: 'projectDetails.siteSurvey',
    US: 'projectDetails.loadCalculation',
    FR: 'projectDetails.siteSurvey',
};
export var loadCalculationLabel = {
    AU: 'projectDetails.batterySizingDetails',
    DE: 'projectDetails.siteSurveyDetails',
    BE: 'projectDetails.siteSurveyDetails',
    NL: 'projectDetails.siteSurveyDetails',
    AT: 'projectDetails.siteSurveyDetails',
    US: 'projectDetails.backupLoadDetails',
    CH: 'projectDetails.siteSurveyDetails',
    FR: 'projectDetails.siteSurveyDetails',
};
export var loadTemplateDownloadLabel = {
    AU: 'Battery Sizing Tool.xlsx',
    DE: 'Enphase Site Survey.xlsx',
    BE: 'Enphase Site Survey.xlsx',
    NL: 'Enphase Site Survey.xlsx',
    AT: 'Enphase Site Survey.xlsx',
    US: 'Backup Load details.xlsx',
    CH: 'Enphase Site Survey.xlsx',
    FR: 'Enphase Site Survey.xlsx',
};
export var REQUEST_LISTING_COLUMNS = [
    {
        id: 'drs_id',
        label: I18n.t('requestDetails.requestId'),
        minWidth: 170,
        sortable: true,
        key: 'drsId',
        hasSearch: true,
        userTypes: userTypes,
    },
    {
        id: 'installer_name',
        label: I18n.t('requestDetails.installerName'),
        minWidth: 170,
        sortable: true,
        key: 'installerName',
        hasSearch: true,
        userTypes: [userTypes[1], userTypes[2], userTypes[4]],
    },
    {
        id: 'site_id',
        label: I18n.t('requestDetails.systemId'),
        minWidth: 170,
        sortable: true,
        key: 'siteId',
        hasSearch: true,
        userTypes: userTypes,
    },
    {
        id: 'site_details.name',
        label: I18n.t('requestDetails.systemName'),
        minWidth: 170,
        sortable: true,
        key: 'systemName',
        hasSearch: true,
        userTypes: userTypes[0],
    },
    {
        id: 'project_details.customer_name',
        label: I18n.t('requestDetails.customerName'),
        minWidth: 170,
        sortable: true,
        key: 'customerName',
        hasSearch: true,
        userTypes: userTypes.slice(1),
    },
    {
        id: 'project_details.zipcode',
        label: I18n.t('requestDetails.zipState'),
        minWidth: 170,
        sortable: true,
        key: 'zipState',
        hasSearch: true,
        userTypes: [userTypes[0]],
    },
    {
        id: 'current_status.name',
        label: I18n.t('requestDetails.status'),
        minWidth: 170,
        sortable: true,
        key: 'status',
        userTypes: userTypes,
    },
    {
        id: 'project_details.type',
        label: I18n.t('requestDetails.type'),
        minWidth: 170,
        sortable: false,
        key: 'type',
        userTypes: [userTypes[1], userTypes[2], userTypes[3], userTypes[4]],
    },
    {
        id: 'current_reviewer',
        label: I18n.t('requestDetails.assignedTo'),
        minWidth: 170,
        sortable: false,
        key: 'assignedTo',
        userTypes: [userTypes[1], userTypes[2], userTypes[4]],
    },
    {
        id: 'application_date',
        label: I18n.t('requestDetails.createdOn'),
        minWidth: 100,
        sortable: true,
        key: 'applicationDate',
        userTypes: userTypes,
    },
    {
        id: 'last_modified_date',
        label: I18n.t('requestDetails.updatedOn'),
        minWidth: 100,
        sortable: true,
        key: 'lastUpdatedDate',
        userTypes: userTypes,
    },
];
export var INSTALL_DATE_LIST = [
    {
        key: 'today',
        text: 'requestDetails.today',
    },
    {
        key: 'past_7_days',
        text: 'requestDetails.pastDays',
    },
    {
        key: 'month_date',
        text: 'requestDetails.monthToData',
    },
    {
        key: 'year_date',
        text: 'requestDetails.yearToDate',
    },
    {
        key: 'specific_date',
        text: 'requestDetails.specificDate',
    },
    {
        key: 'all_dates_before',
        text: 'requestDetails.allDatesBefore',
    },
    {
        key: 'all_dates_after',
        text: 'requestDetails.allDatesAfter',
    },
    {
        key: 'date_range',
        text: 'requestDetails.dateRange',
    },
    {
        key: 'clear_dates',
        text: 'requestDetails.clearDate',
    },
];
export var PAGE_SIZE_OPTIONS = [10, 25, 100];
export var ACCEPTABLE_DOC_FORMAT = 'image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export var ACCEPTABLE_IMAGE_FORMAT = 'image/*';
export var ACCEPTABLE_LOAD_CALCULATION_FORMAT = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv';
export var ACCEPTABLE_SLD_DOC_FORMAT = 'application/pdf';
export var MAX_FILE_UPLOAD_SIZE = '100';
export var ONE_DAY_TIME_MS = 86400000;
export var otherDocumentList = [
    'requestDetails.utilityBill',
    'requestDetails.equipmentElevation',
    'requestDetails.planSet',
];
export var CUSTOMER_SUPPORT_LINK = 'https://support.enphase.com/s/contact-us';
export var SERVICE_CHARGE = 40;
export var AUTO_SAVE_TIME = 8000; // in miliseconds
export var APPLICATION_STATES = {
    APPLICATION_INCOMPLETE: {
        name: 'constants.applicationIncomplete',
        id: 0,
        nameForInstaller: 'constants.applicationIncomplete',
    },
    NEW_APPLICATION_RECEIVED: {
        name: 'constants.newApplicationReceived',
        nameForInstaller: 'constants.newApplicationSubmitted',
        id: 1,
    },
    NEW_APPLICATION_ASSIGNED: {
        name: 'constants.newApplicationAssigned',
        nameForInstaller: 'constants.newApplicationSubmitted',
        id: 2,
    },
    APPLICATION_UNDER_REVIEW: {
        name: 'constants.applicationUnderReview',
        nameForInstaller: 'constants.applicationUnderReview',
        id: 3,
    },
    RESUBMISSION_REQUESTED: {
        name: 'constants.resubmissionRequested',
        nameForInstaller: 'constants.resubmissionRequested',
        id: 4,
    },
    RESUBMISSION_RECEIVED: {
        name: 'constants.resubmissionReceived',
        nameForInstaller: 'constants.resubmissionSubmitted',
        id: 5,
    },
    APPLICATION_REVIEW_COMPLETED: {
        name: 'constants.applicationReviewCompleted',
        nameForInstaller: 'constants.applicationReviewCompleted',
        id: 6,
    },
    REQUEST_FOR_TIER2_APPROVAL: { name: 'constants.requestForTier2Approval', id: 7 },
    TIER2_APPLICATION_ASSIGNED: { name: 'constants.tier2ApplicationAssigned', id: 8 },
    TIER2_APPROVAL_UNDER_PROGRESS: { name: 'constants.tier2ApprovalUnderProgress', id: 9 },
    TIER2_RESUBMISSION_REQUESTED: {
        name: 'constants.tier2ResubmissionRequested',
        nameForInstaller: 'constants.resubmissionRequested',
        id: 10,
    },
    TIER2_RESUBMISSION_RECEIVED: {
        name: 'constants.tier2ResubmissionReceived',
        nameForInstaller: 'constants.resubmissionSubmitted',
        id: 11,
    },
    TIER2_APPROVAL_COMPLETED: {
        name: 'constants.tier2ApprovalCompleted',
        nameForInstaller: 'constants.applicationReviewCompleted',
        id: 12,
    },
};
export var drsUnivCourseMappingStg = {
    36: 'Enphase Storage Design Certification Training',
    37: 'Certificación en Diseño de Almacenamiento Enphase',
    92: 'Design with Third Generation of Enphase Energy System',
    93: 'Diseño con la tercera generación del Sistema de Energía Enphase',
};
export var drsUnivCourseMappingProd = {
    36: 'Enphase Storage Design Certification Training',
    37: 'Certificación en Diseño de Almacenamiento Enphase',
    93: 'Design with Third Generation of Enphase Energy System',
    95: 'Diseño con la tercera generación del Sistema de Energía Enphase',
};

import React from 'react';
import { connect } from 'react-redux';
import { getS3DownloadUrl, getS3Url, putToS3 } from '../Installer/action';
import { getMessagesList, sendMessage, updateChatStatus } from './actions';
import ChatBox from './ChatBox';
import { removeKey } from '../../utils/localStorage';
import settings from '../../utils/settings';

export class MessageHistory extends React.Component {
  componentDidMount() {
    this.fecthAllChats();
  }

  fecthAllChats() {
    this.props.getMessagesList({
      drsId: this.props.drsId,
      failureCb: () => {
        removeKey('_enlighten_manager_token');
        removeKey('_solargraf_token');
        window.location.replace(settings.enlightenUrl('logout'));
      },
    });
  }

  render() {
    return (
      <ChatBox
        messages={this.props.messageList}
        sendMessage={this.props.sendMessage}
        appId={this.props.drsId}
        fetchAllChats={this.fecthAllChats.bind(this)}
        getPresingedURL={this.props.getPresingedURL}
        uploadToS3={this.props.uploadToS3}
        handleClose={this.props.handleClose}
        open={this.props.open}
        updateChatStatus={this.props.updateChatStatus}
        unreadMessageCount={this.props.unreadMessagesCount}
        isFromAdmin={this.props.isFromAdmin}
        isFromApplicationStatus={this.props.isFromApplicationStatus}
        isInstaller={this.props.isInstaller}
        getS3DownloadUrl={this.props.getS3DownloadUrl}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMessagesList: (drsId, successCbk) => dispatch(getMessagesList(drsId, successCbk)),
  sendMessage: (payload) => dispatch(sendMessage(payload)),
  getPresingedURL: (payload) => dispatch(getS3Url(payload)),
  uploadToS3: (payload) => dispatch(putToS3(payload)),
  getS3DownloadUrl: (payload) => dispatch(getS3DownloadUrl(payload)),
  updateChatStatus: (payload) => dispatch(updateChatStatus(payload)),
});

const mapStateToProps = (state) => ({
  drsId: state.installerReducer.drsId,
  messageList: state.chatReducer.messageList,
  unreadMessagesCount: state.chatReducer.unreadMessagesCount,
  isInstaller: state.appReducer.isInstaller,
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageHistory);
